import React from 'react'
import tablepic from '../images/FD24table.jpg'

export default function About() {
  return (
    <div style={{display: 'inline'}} className='main'>
      <h2>about us</h2>
      bark bark!<br/><br/>

      jp & hunty here! we are the double dogs hehe 👌🐶🐶✌️<br/><br/>

      we've set out to bring striking designs to the world of furry apparel. think of us as the 
      vintage skateshop of the furry world.<br/><br/>

      (also here’s our personal art sites!)<br/>
      <a target="_blank" rel="noopener noreferrer" href="https://ethanjp.dog/">jp’s site</a><br/>
      <a target="_blank" rel="noopener noreferrer" href="https://huntysdoodles.com/">hunty’s site</a>
      <div style={{display:'flex',justifyContent:'center',width:'100%',margin:'15px 0'}}><img style={{width:'100%'}} alt='us' src={tablepic}/></div>
      (our display at Furrydelphia 2024)
    </div>
  )
}
