import React from 'react'
import hero from '../images/dd header.png'
// import SocialIcons from './SocialIcons'

export default function Home() {
  return (
    <div className='main'>
      {/* profile icon */}
      <div style={{display:'flex',justifyContent:'center',width:'100%'}}><img style={{width:'100%'}} alt='phone dog' src={hero}/></div>
      {/* header title */}
      {/* social icon links */}
      {/* <SocialIcons /> */}
      {/* link list */}
      <div style={{display:'flex', flexFlow:'column', marginTop:'8px'}}>
        <a href='https://www.double-dog.com/' className='button' target="_blank" rel="noopener noreferrer">👕 merch</a>
        <a href='https://t.me/DoubleDogStudio' className='button' target="_blank" rel="noopener noreferrer">💬 telegram channel</a>
        <a href='https://x.com/doubledogmerch' className='button' target="_blank" rel="noopener noreferrer">🐥 twitter</a>
        <a href='https://instagram.com/doubledogmerch' className='button' target="_blank" rel="noopener noreferrer">📸 instagram</a>
        <a href='./about' className='button'>🐶🐶 about us</a>
        {/* <hr style={{borderTop:'4px solid #b4686d', width:'100%', margin:'20px 0'}}/> */}
      </div>
    </div>
  )
}
