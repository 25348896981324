import './App.css';
import { Routes, Route } from 'react-router-dom'
import Home from './components/Home'
import HowToPay from './components/HowToPay'
import Tabling from './components/Tabling';
import About from './components/About';

function App() {
  return (
    <div className="App">
      <Routes>
        {/* home - profile pic, social icons, main page links, about section */}
        <Route exact path='/' element={<Home/>}/>
        {/* about us */}
        <Route path='/about' element={<About/>}/>
        {/* tabling */}
        <Route path='/tabling' element={<Tabling/>}/>
        {/* pay me (for remote displays) - payment processor profile pic, payment options, full shop link */}
        <Route path='/how-to-pay' element={<HowToPay/>}/>
      </Routes>
    </div>
  );
}

export default App;
