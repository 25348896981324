import React from 'react'
import About from './About'

export default function Tabling() {
  return (
    <div className='main'>
      {/* about me */}
      <About/>
      {/* click to view homepage */}
      <a className='link' href='./'>&lt;- view hunty's doodles homepage</a>
    </div>
  )
}
